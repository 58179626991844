.subtitle{
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.home_slider_{
    &background{
        background: url(./slider-images/home_slider_background.png);
    }
    &companies{
        background: url(./slider-images/home_slider_companies.png);
    }
    &products{
        background: url(./slider-images/home_slider_products.png);
    }
    &people{
        background: url(./slider-images/home_slider_people.png);
    }
        background-repeat: no-repeat;
}
.single-slider-background::before{
    background-image: linear-gradient(309deg, #CA1E45 0%, #9B0325 99.41%);
}