/*----------------------------------------*/
/*  06. COUNTER CSS START
/*----------------------------------------*/

.counter{
    &__area{
        position: relative;
        &-2{
            &::before{
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 330%;
                background: url('../img/bg/wave-bg.png');
                z-index: -1;
            }
        }
    }
    &__inner{
        @include border-radius(6px);
        @include box-shadow(0px 0px 46px 0px rgba(0, 0, 0, 0.25));
        padding: 90px 0;
        padding-bottom: 65px;
    }
    &__item{
        & h2{
            font-size: 50px;
            margin-bottom: 0;
            font-weight: 900;
            line-height: 1;
            color: $firenze-main;
        }
        & span{
            text-transform: capitalize;
        }
        &-2{
            & span{
                color: $black-soft-2;
            }
        }
    }
}