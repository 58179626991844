.products-container {
  width: 100%;
  // max-height: 750px;
  .image {
    flex: 1;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .products {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex: 5;
    padding: 60px 120px;
    @media (max-width: 767px) {
      padding: 60px 20px;
    }
    .title {
      color: #000;
      font-size: 45px;
      font-weight: 700;
      line-height: 125.5%; /* 70.28px */
    }
    .subtitle {
      color: #d31f47;
      font-size: 22px;
      font-weight: 400;
    }
    li {
      margin-bottom: 20px;
      &:hover {
        span {
          color: #d62f22;
          i {
            background: #d62f22;
            color: white;
          }
        }
      }
      span {
        display: block;
        color: #000;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        position: relative;
        padding-left: 30px;
        & i {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: inline-block;
          width: 20px;
          height: 20px;
          font-size: 10px;
          line-height: 20px;
          text-align: center;
          color: white;
          border-radius: 50%;
          background: #d62f22;
          margin-right: 8px;
        }
      }
    }
  }
}
