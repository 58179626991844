.container {
  .footer-navigation {
    gap: 36px;
    @media (max-width: 767px) {
     flex-direction: column;
    }
    li {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
  }
  .zaphify {
    width: 100%;
    border-top: 1px solid #DDD;
    p {
      text-align: center;
      border-bottom: 1px solid #8e38fb;
      color: #8e38fb;
      font-family: Montserrat;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
    }
    img{
        margin: 0 auto;
    }
  }
}
