.circle-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 35%;
   }
  .circle {
    width: 90px;
    height: 90px;
    border: 2px solid #d62f22;
    .icon {
    }
  }
  .text {
    text-transform: uppercase;
    color: #d31f47;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
  }
}
